.tableFixHead {
  overflow-y: auto;
  height: 270px;
}
.tableFixHead2 {
  overflow-y: auto;
  max-height: 270px;
}
.tableFixHead3 {
  overflow-y: auto;
  max-height: 500px;
}
.tableFullheight {
  height: 500px;
}
label.checkbox-label input[type='checkbox'] {
  position: relative;
  vertical-align: middle;
  bottom: 1px;
}
.zoneImageDiv {
  position: relative;

  top: 0;
  width: 100%;
}

// ::-webkit-scrollbar {
//     width: 12px;   // for vertical scroll bar
//     //height: 8px;  // for horizontal scroll bar

// }
// ::-webkit-scrollbar-track {
// background: rgb(30, 103, 121);
// }

// ::-webkit-scrollbar-thumb{
// background-color: #FF6F00;
// border-radius: 20px;       /* roundness of the scroll thumb */
// border: 1px solid black;
// }
.tableFixHead thead th {
  position: sticky;
  top: 0;
  color: #ff6f00;
  background-color: rgb(30, 103, 121);
  z-index: 110;
}
.tableFixHead2 thead th {
  position: sticky;
  top: 0;
  color: #ff6f00;
  background-color: rgb(30, 103, 121);
  z-index: 110;
}
.tableFixHead3 thead th {
  position: sticky;
  top: 0;
  color: #ff6f00;
  background-color: rgb(30, 103, 121);
  z-index: 110;
}
.tableFixHeadCard thead th {
  position: sticky;
  top: 0;
  color: #ff6f00;
  background-color: rgb(30, 103, 121);
  z-index: 110;

  /* Just common table stuff. */
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 8px 16px;
  }
}

th {
  background: #32a8a8;
}

.btn-default {
  background-color: #ff6f00;

  color: #005573;

  border-color: #005573;
}

.btn-default:hover,
.open .dropdown-toggle.btn-default {
  background-color: #eea369;

  color: #05333a;

  border-color: #05333a;
}
.btn-default:focus,
.btn-default:active {
  background-color: #eea369;

  color: #09394d;

  border-color: #09394d;

  box-shadow: 0 0 0 0.2rem rgb(8, 108, 116) !important;
}

input[type='checkbox'] {
  accent-color: #ff6f00;
}

.task_selected_picture {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  flex-direction: column;
  // margin-bottom: 20px;
  @media (min-width: 600px) and (orientation: landscape), (min-width: 1280px) {
    flex-direction: row;
  }
}

.table-hover > tbody > tr:hover {
  background-color: #ff6f00;
}

.dashboard__btn {
  border-radius: 40px;
  border-color: rgba(182, 197, 202, 0.35);
  box-shadow: 0 0 2px 2px rgba(190, 189, 189, 0.25); //rgba(41, 185, 238, 0.25);
  color: black;
  &:hover {
    background-color: #919ea3;
  }
  &:active {
    color: #00c1b6;
  }
}

// .dashboard__btn--div {
//     padding-top : 1px;
//         display: flex;
//         gap: 10px;
//     }

@media print {
  /*This is the important to add page break!*/
  .page-break-line {
    page-break-before: always !important;
  }
}
