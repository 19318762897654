@import '../node_modules/@ag-grid-community/all-modules/dist/styles/ag-grid.min.css';
@import '../node_modules/@ag-grid-community/all-modules/dist/styles/ag-theme-material.min.css';

* {
  box-sizing: border-box;
  font-family: 'Noto Sans', 'Noto Sans Hebrew';
}

@font-face {
  font-family: 'Quicksand';
  src: url(assets/fonts/Quicksand/Quicksand-SemiBold.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Quicksand-Bold';
  src: url(assets/fonts/Quicksand/Quicksand-Bold.ttf) format('truetype');
  font-weight: normal;
}

html {
  font-size: 20px;
  --background-gray: #f6f6f6;
  height: 100%;
}

#root {
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-height: 1500px) {
  html {
    font-size: 12px;
  }

  #root {
    padding: 0;
  }
}

/* highcharts-overrides.css */
[data-highcharts-chart] {
  overflow: visible !important;
}

body {
  height: 100%;
  --scroll-bar-width: 12px; /* 5px; */
  margin: 0;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  height: 12px; /* 5px */
}

/* Track */
::-webkit-scrollbar-track {
  /* background:transparent; */
  background: #dce7e7;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #a2b6b6;
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid white;
  /* background: #0a68eb; */
}

/* Handle on hover */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #e0e0e1;
}

/* input[type="date"]::-webkit-calendar-picker-indicator { */
/*display: none;*/
/*-webkit-appearance: none;*/
/* filter: invert(1); */
/* } */

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
}

.ag-center-cols-viewport {
  overflow-x: hidden;
}

.ag-theme-material ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-theme-material ::-webkit-scrollbar-track {
  background: transparent;
}

.ag-theme-material ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #e3e3e3;
}

.ag-theme-material ::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
}

.ag-theme-material .ag-cell {
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding: 6px;
  text-transform: uppercase;
  font-size: 14px;
}

.ag-theme-material .ag-header-cell:hover,
.ag-theme-material .ag-header-group-cell:hover {
  background: transparent !important;
}

.ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
  left: calc(50% - 2px);
}
.ag-theme-material .ag-header-cell-resize:after {
  content: '';
  position: absolute;
  z-index: 1;
  display: block;
  width: 2px;
  height: 30%;
  top: calc(50% - 30% * 0.5);
  background-color: #dde2eb;
}

.ag-header-group-cell-label {
  font-weight: bold;
  color: #1e6779;
}

.ag-theme-material.grid-small .ag-cell {
  font-size: 12px;
}

.ag-theme-material.grid-small .ag-header-cell,
.ag-theme-material.grid-small .ag-header-group-cell {
  font-size: 12px;
}

.react-datepicker__input-container input {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  color: black;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #1f232d;
  border-radius: 4px;
  font-size: 14px;
  height: 39px;
}

.dashboard-date-picker {
  width: 280px;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.7rem !important;
}

.react-datepicker {
  font-size: 1rem !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  font-family: 'Noto Sans', 'Noto Sans Hebrew' !important;
}

.react-datepicker__tab-loop {
  margin-top: 0 !important;
}

.highcharts-credits {
  display: none;
}

.charts.ag-theme-material .ag-cell {
  font-size: 12px;
  line-height: 32px;
}

.charts.ag-theme-material .ag-header-cell,
.charts.ag-theme-material .ag-header-group-cell {
  font-size: 12px;
}

@media print {
  .ag-root-wrapper,
  .ag-root,
  .ag-root-wrapper-body {
    height: auto !important;
    overflow: visible !important;
    display: flex !important;
  }
  .ag-layout-normal {
    display: flex;
  }
  .ag-center-cols-viewport {
    height: calc(100% + 0px) !important;
  }
  .ag-body-viewport .ag-center-cols-container {
    height: 250px !important;
  }
}

.slick-next,
.slick-prev {
  color: #ff6f00 !important;
  width: 6em !important;
  height: 6em !important;
}

.slick-next:hover,
.slick-prev:hover {
  color: white !important;
}

.scehduler-auto-sizer {
  width: 100% !important;
}
.scehduler-auto-sizer > div {
  width: 100% !important;
  zoom: 1;
}

@media screen and (max-width: 600px) {
  .scehduler-auto-sizer > div {
    zoom: 0.8;
  }
}

/*.scehduler-auto-sizer > div > div > div:first-child {*/
/*  position: inherit;*/
/*}*/
/*.scehduler-auto-sizer > div > div > div:first-child span {*/
/*  border: none;*/
/*}*/
/*.scehduler-auto-sizer .rs__today_cell, .rs__cell.rs__header  {*/
/*display: none;*/
/*}*/
