.daily-dialog-content {
  padding: 0;
}

.daily-dialog-actions {
  height: 6rem;
  gap: 1rem;
  padding: 0;
  // @media  (min-width: 1180px)  { //standard screen
  //     height: 9rem;
  // }
}

.daily-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @media  (min-width: 1180px)  { //standard screen
  //     padding: 10px 0;
  // }
}

.daily-dialog__close-btn {
  font-size: 33px;
}
