@import '~bootstrap/scss/bootstrap.scss';

.modal-content {
  width: auto;
}
//   .modal-body{
//     padding: 2px !important;

// }
// // .report-dialog{
//     border-radius: 40;
//     width: 740px;
//     height: 700px;
// }

// .modal-dialog-centered{

//     width: 620px !important;
// }
.report-dialog-content {
  // padding: 0;
  //  border: 4px;
  height: 780px;
  // display: flex;
  // // justify-content: space-between;
  // // margin-bottom: 2em;
  // flex-direction: column;
  overflow-y: auto;
  overflow-x: auto;
  width: 600px;
}

.report__page-border {
  border: 1px solid #979797;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 595px;
  height: 1072px;
  font-size: 10px;
  direction: ltr;
  margin-left: 90px;
  // padding-bottom: 30px;
  // margin-top: 25px;
  position: relative;
}

.page-one__content {
  padding-top: 15px;
}

.report__page-title2 {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}
.report__page-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.page-one__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #000000;
}

.report__divider {
  background-color: #e2e1e1;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}

.report__metadata {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  row-gap: 6px;
  column-gap: 40px;
  margin-bottom: 1em;
  margin-top: 1.2em;
}

.report__section-title {
  font-size: 1.2em;
  text-decoration: underline;
  display: block;
  font-weight: 400;
}

.report__section-title--weight-check::after {
  content: ' Good';
  color: #00c1b6;
  text-decoration: unset;
}

.report__crane-outputs {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.report__page--footer {
  display: flex;
  position: absolute;
  bottom: 1rem;
  justify-content: space-between;
  width: calc(100% - 2rem);
}

.page-four__divider {
  width: 1px;
  height: 1em;
  background-color: #e2e1e1;
  margin: 0 20px;
}

.page-four__axis {
  display: flex;
}

.page-four__gear {
  line-height: 1em;
}

.page-four__gear-color {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-left: 5px;
}

.page-four__gear-charts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 20px;
  margin-top: 20px;
}

.page-four__handler {
  display: flex;
  gap: 2em;
}
.dialog-content {
  padding: 0;
  position: relative;
}

.dialog-actions {
  height: 6rem;
  gap: 1rem;
  padding: 0;
  // @media  (min-width: 1180px)  { //standard screen
  //     height: 9rem;
  // }
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @media  (min-width: 1180px)  { //standard screen
  //     padding: 10px 0;
  // }
}
.dialog__close-btn {
  font-size: 33px;
}

.btn-default {
  background-color: #ff6f00;
  color: #005573;
  border-color: #005573;
}
.btn-standart {
  border-radius: 40px;
}

.btn-default:active .open .dropdown-toggle.btn-default {
  background-color: #ff6f00;
  color: #005573;
  border-color: #005573;
}
.btn-default:hover {
  background-color: #ff6f00;
  color: #005573;
  border-color: #09394d;
  box-shadow: 0 0 0 0.2rem rgb(8, 108, 116) !important;
}

.custom-btn {
  width: 70px;
  height: 70px;
  font-size: medium;
  margin-bottom: 40px;
  margin-right: 40px;
}
.work-day__Report {
  // margin-top:60px;
  display: flex;
  align-items: center;

  justify-content: space-around;
}
.work-day__Report2 {
  // margin-top:60px;
  display: flex;
  flex-direction: column;
  // align-items:flex-start ;

  justify-content: flex-start;
}
// .lifts-day__btn {
//     display: flex;
//     align-items: center;
//     justify-content: space-around;

// }
.lift-day__btn__div {
  font-size: 13px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lifts-day__btn {
  width: 40px;
  height: 40px;
  font-size: medium;
  border-color: #005573; //#FF6F00;#005573
}
.chart-header {
  display: inline-block;
  background-color: rgba(#ffffff, 0);
  font-weight: bold;
  font-size: 12px;
}
.page-break-line {
  /**Make sure you don't give any height,border. Because giving them gives an 
    extra page.
    */
  visibility: hidden;
}
