.recording-progress-bar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .progress-bar-background {
    height: 20px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;

    .uploading-progress {
      height: 100%;
      background-color: #ff6f00;
      transition: width 0.2s ease;
    }
  }

  .progress-text {
    margin-left: 10px;
    font-size: 16px;
  }
}
