@import '~bootstrap/scss/bootstrap.scss';

.login-page {
  background-color: var(--background-gray);
  background-image: url('../assets/images/login.gif');
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 800px) {
    background-size: cover;
  }

  .login-page__text-field {
    width: 20rem;
    height: 4rem;
    border-radius: 40px;
    border-color: rgba(182, 197, 202, 0.35);
    box-shadow: 0 0 5px 5px rgba(190, 189, 189, 0.25); //rgba(41, 185, 238, 0.25);
    padding-left: 25px;

    //  text-align: center;
    // &:hover {
    //     color: #FF6F00;
    //     background-color:#005573;
    //   }
    //   &:active {
    //     color:#FF6F00;
    //     background-color:#005573;
    //     ;
    //   }
  }

  .login-page__btn {
    width: 20rem;
    height: 3rem;
    border-radius: 40px;
    background-color: #ff6f00;
    color: #005573;
    padding: 0.5px;
    border-color: rgba(182, 197, 202, 0.35);
    box-shadow: 0 0 5px 5px rgba(190, 189, 189, 0.25); //rgba(0, 86, 117, 0.25);
    &:hover {
      color: #005573;
      background-color: #ce5b03;
    }
    &:active {
      color: #005573;
      background-color: #9e4501;
    }
  }
  .login-page__smallbtn {
    width: 10rem;
    height: 2rem;
    border-radius: 40px;
    background-color: #ff6f00;
    color: #005573;
    border-color: #005573;
    vertical-align: middle;
    border-width: 1px;
    text-decoration: none;
    text-align: center;
    padding: 1px;
    &:hover {
      color: #005573;
      background-color: #ce5b03;
    }
    &:active {
      color: #005573;
      background-color: #9e4501;
    }
  }
  .mybadge {
    color: #005573;
    background-color: #ff6f00;
    text-decoration: none;
  }
}
.app-header__icon-btn {
  padding: 0;
  width: 3rem;
  height: 3rem;
  position: relative;
}

.icon__btn {
  display: block;
  //   height: 34px;
  //   width: 34px;
  padding: 6px;
  border-radius: 50%;
  background-color: rgba(#ffffff, 0);
  border-color: rgba(#ffffff, 0);
  box-shadow: 0 0 2px 2px rgba(190, 189, 189, 0.2); //rgba(41, 185, 238, 0.25);
  color: rgb(129, 128, 128);
  margin: 10px;
  &:hover {
    background-color: rgba(243, 238, 238, 0.664);
  }
  &:active {
    color: #ff6f00;
  }
}
.input-group-addon i {
  margin-left: -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #6c757d;
}
.icon__btn {
  display: block;
  height: 3.5rem;
  width: 3.5rem;
  padding: 6px;
  border-radius: 40%;

  background-color: rgba(#ffffff, 0);
  border-color: rgba(#ffffff, 0);
  box-shadow: 0 0 2px 2px rgba(190, 189, 189, 0.2); //rgba(41, 185, 238, 0.25);
  color: black;
  margin: 10px;
  &:hover {
    background-color: rgba(243, 238, 238, 0.664);
  }
  &:active {
    color: #ff6f00;
  }
}
.login-page__text-field_pass {
  width: 15rem;
  height: 4rem;
  border-radius: 40px;
  border-color: rgba(182, 197, 202, 0.35);
  box-shadow: 0 0 5px 5px rgba(190, 189, 189, 0.25); //rgba(41, 185, 238, 0.25);
  padding-left: 25px;
  display: block;
  //  text-align: center;
  // &:hover {
  //     color: #FF6F00;
  //     background-color:#005573;
  //   }
  //   &:active {
  //     color:#FF6F00;
  //     background-color:#005573;
  //     ;
  //   }
}
